import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Isotope from "isotope-layout";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const infoURL = "https://contenu.simonbeuret.ch/wp-json/wp/v2/info";
const projetUrl = "https://contenu.simonbeuret.ch/wp-json/wp/v2/projet?per_page=100";

function App() {
  const [info, setInfo] = useState([]);
  const [projet, setProjet] = useState([]);
  let contact = useRef();
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [opacity, setOpacity] = useState("1");
  const [opacityGrid, setOpacityGrid] = useState("0");
  const [transition, setTransition] = useState("1500ms");

  useEffect(() => {
    // contact = document.querySelector(".contact-tab");
    axios.get(infoURL).then((response) => {
      setInfo(response.data[0]);
    });
    axios.get(projetUrl).then((response) => {
      let arrayProject = response.data;
      let ordre = 4000;
      for (const singleProject of arrayProject) {
        if (!singleProject.acf.ordre) {
          singleProject.acf.ordre = ordre;
          ordre++;
        }
      }
      arrayProject.sort((a, b) => (parseInt(a.acf.ordre) > parseInt(b.acf.ordre) ? 1 : -1));
      setProjet(arrayProject);
    });
    document.title = `Simon Beuret`;
  }, []);

  useEffect(() => {
    if (projet.length != 0) {
      setOpacity("0");
      setOpacityGrid("1");
    }
    isotope.current = new Isotope(".grid", {
      itemSelector: ".grid-item",
      masonry: {
        columnWidth: 50,
        rowHeight: 350,
        fitWidth: true,
      },
    });
    // cleanup
    return () => isotope.current.destroy();
  }, [projet]);

  // const isoTop = () => {};

  useEffect(() => {
    filterKey === "*" ? isotope.current.arrange({ filter: `*` }) : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  function showContact() {
    contact.current.style.top = 0;
    // document.getElementById("demeter").style.position = "fixed";
    // document.getElementById("demeter").style.overflow = "hidden";
  }
  function hideContact() {
    contact.current.style.top = "";
    // document.getElementById("demeter").style.position = "static";
    // document.getElementById("demeter").style.overflow = "auto";
  }
  const navSlide = () => {
    const burger = document.querySelector(".burger");
    const nav = document.querySelector(".nav-links");
    const navLinks = document.querySelectorAll(".nav-links li");

    // toggle
    nav.classList.toggle("nav-active");
    // animation des liens
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
      }
    });
    // burger
    burger.classList.toggle("toggle");
  };

  return (
    <div className="App">
      <nav className="filters">
        <div className="logo small">
          <label>
            <input onClick={handleFilterKeyChange("*")} type="radio" name="filter" value="*" /> simon beuret
          </label>
        </div>
        <ul className="nav-links">
          <div className="logo big">
            <label>
              <input onClick={handleFilterKeyChange("*")} type="radio" name="filter" value="*" /> simon beuret
            </label>
          </div>
          <li>
            <input onClick={handleFilterKeyChange("comics")} type="radio" name="filter" value=".comics" id="comics1" />
            <label htmlFor="comics1">comics</label>
          </li>
          <li>
            <input onClick={handleFilterKeyChange("illustrations")} type="radio" name="filter" value=".illustrations" id="illustrations1" />
            <label htmlFor="illustrations1">Illustration</label>
          </li>
          <li>
            <input onClick={handleFilterKeyChange("video")} type="radio" name="filter" value=".video1" id="video1" />
            <label htmlFor="video1">Video</label>
          </li>
          <li>
            <label onClick={() => showContact()}>info</label>
          </li>
        </ul>
        <div onClick={() => navSlide()} className="burger">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
      <div className="grid" id="demeter">
        <div style={{ opacity: opacity }} className="loader">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        {
          projet[0] &&
            projet.map((thisProjet, index) => {
              let width = 235;
              let thisWidth = thisProjet.acf.book.width;
              let ratio = width / thisWidth;
              let height = thisProjet.acf.book.height * ratio;
              let img;
              if (!isMobile) {
                img = new Image();
                img.src = thisProjet.acf.book.url;
              }
              const gif = new Image();
              gif.src = thisProjet.acf.book_gif;
              return (
                <div style={{ opacity: opacityGrid, transition: transition }} key={index} className={"grid-item " + thisProjet.acf.categorie}>
                  <Link to={thisProjet.slug}>
                    <img
                      style={{ height: height }}
                      loading="eager"
                      className="book-index"
                      src={isMobile ? gif.src : img.src}
                      alt={thisProjet.slug}
                      onMouseOver={(e) => {
                        if (!isMobile) {
                          e.currentTarget.src = gif.src;
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (!isMobile) {
                          e.currentTarget.src = img.src;
                        }
                      }}
                    />
                  </Link>
                </div>
              );
            })
          // setMapCompleted(true);
        }
      </div>

      <div ref={contact} className="contact-tab">
        <div className="text-container">
          <div className="info-text">
            {info.content && <div className="contact-text" dangerouslySetInnerHTML={{ __html: info.content.rendered }} />}
            <div className="iconcontact">
              <p className="contact-text2">
                <a href="mailto:simon.beuret@gmail.com">
                  <i className="fas fa-envelope" />
                </a>
                <a rel="noreferrer" href="https://www.facebook.com/simonbeuretillustrateur/" target="_blank">
                  <i className="fab fa-facebook" />
                </a>
                <a rel="noreferrer" href="https://www.instagram.com/the_oobserver/" target="_blank">
                  <i className="fab fa-instagram-square" />
                </a>
              </p>
            </div>
            <p className="contact-text3">
              @ {new Date().getFullYear()} Simon Beuret
              <br />
              All rights reserved. <br />
              Website by{" "}
              <a rel="noreferrer" href="http://www.chadimessmer.ch" target="_blank">
                Chadi Messmer
              </a>
            </p>
          </div>
        </div>
        <a rel="noreferrer" onClick={() => hideContact()}>
          <i className="fas fa-arrow-circle-up fa-3x"></i>
        </a>
      </div>
    </div>
  );
}

export default App;
