import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";
import Fade from "react-reveal/Fade";

const projetUrl = "https://contenu.simonbeuret.ch/wp-json/wp/v2/projet?per_page=100";

function Projet() {
  const [projet, setProjet] = useState([]);
  const [title, setTitle] = useState("");
  const location = useLocation();
  let thisPage = location.pathname.substring(1);

  useEffect(() => {
    axios.get(projetUrl).then((response) => {
      //   setProjet(response.data);
      let thisProjet = response.data.filter((obj) => {
        return obj.slug === thisPage;
      });
      setProjet(thisProjet);
    });
  }, []);

  useEffect(() => {
    document.title = `${title} - Simon Beuret`;
  }, [title]);

  useEffect(() => {
    if (projet[0]) {
      setTitle(projet[0].title.rendered);
    }
  }, [projet]);

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  return (
    <div>
      <nav className="filters nav-projet">
        <div className="logo-project">
          <Link to="/">Simon Beuret</Link>
        </div>
      </nav>
      {projet[0] && projet[0].acf.type === "vertical" && (
        <>
          <div className="work">
            <div className="infos">{projet[0] && <p className="text-label" dangerouslySetInnerHTML={{ __html: projet[0].content.rendered }} />}</div>
          </div>
          <div className="content">
            {projet[0] &&
              projet[0].acf.images.map((thisProjet, index) => (
                <Fade key={index}>
                  <img src={thisProjet.full_image_url} alt={thisProjet.title} className="image-work" />
                </Fade>
              ))}
          </div>
        </>
      )}
      {projet[0] && projet[0].acf.type === "horizontal" && (
        <div className="work">
          <div className="content2">
            <div className="noclass">
              {projet[0] && <p className="text-label horizontal-label" dangerouslySetInnerHTML={{ __html: projet[0].content.rendered }} />}
            </div>

            {projet[0] &&
              projet[0].acf.images.map((thisProjet, index) => (
                <Fade key={index}>
                  <img src={thisProjet.full_image_url} alt={thisProjet.title} className="image-work" />
                </Fade>
              ))}
          </div>
        </div>
      )}
      {projet[0] && projet[0].acf.type === "video" && (
        <Fade>
          <div id="video">
            <p className="text-label horizontal-label" dangerouslySetInnerHTML={{ __html: projet[0].content.rendered }} />

            <div className="video-responsive">
              <YouTube videoId={YouTubeGetID(projet[0].acf.youtube)} />
            </div>
            <div style={{ paddingBottom: "3.5rem" }}>
              {projet[0] &&
                projet[0].acf.images &&
                projet[0].acf.images.map((thisProjet, index) => (
                  <Fade key={index}>
                    <img src={thisProjet.full_image_url} alt={thisProjet.title} className="image-work" />
                  </Fade>
                ))}
            </div>
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Projet;
